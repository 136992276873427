@import "../../../scss/variables";

.auth {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user {
  font-size: 1.75rem;
  font-weight: 400 !important;
  color: $header;
  line-height: normal;
}

.banner {
  background-size: cover;
  background-image: url("https://my.mai.ru/assets/images/welcome.jpg");
  background-repeat: no-repeat;

  @media (max-width: $large) {
    display: none;
  }
}

.link {
  font-weight: 400;
}
