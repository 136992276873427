@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import "variables";
@import "custom";
@import "./reset.scss";

body {
  background-color: $background;
  font-family: "Exo 2", sans-serif;
  color: $black;
  font-size: 16px;
  line-height: normal;
  overflow-x: hidden;
}

.main-container {
  max-width: 1150px;
  padding: 1.75rem;

  @media screen and (max-width: $large) {
    padding: 1.75rem 4rem;
  }

  @media screen and (max-width: $medium) {
    padding: 2.5rem 1.25rem;
  }
}

.content-container {
  background: $white;
  border-radius: 0.25rem;
  padding: 2.5rem;

  @media screen and (max-width: $medium) {
    padding: 2.25rem 1rem;
  }

  @media screen and (max-width: $small) {
    padding: 1.25rem 0.75rem;
  }
}

.content-title {
  position: relative;
  display: inline-block;
  margin-bottom: 3rem;
  font-size: 1.25rem;
  font-weight: 300;
  z-index: 1;

  &::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    height: calc(100% + 5px);
    width: calc(100% + 5px);
    transform: translate(-50%, -50%);
    background: $light-grey;
    border-radius: 0.25rem;
    z-index: -1;
  }

  @media screen and (max-width: $medium) {
    margin-bottom: 2.25rem;
  }
}

.content-item:not(:last-child) {
  margin-bottom: 3rem;

  @media screen and (max-width: $medium) {
    margin-bottom: 2.25rem;
  }
}

.content-list-title {
  font-weight: 700;
  margin-bottom: 1.5rem;

  @media screen and (max-width: $medium) {
    margin-bottom: 1rem;
  }
}

.content-sublist-item:not(:last-child) {
  margin-bottom: 2.25rem;

  @media screen and (max-width: $medium) {
    margin-bottom: 0.75rem !important;
  }
}

.content-sublist-title {
  color: $grey;
  font-weight: 600;
  font-size: 0.75rem !important;
  text-transform: uppercase;
  margin-bottom: 0.75rem;

  @media screen and (max-width: $medium) {
    margin-bottom: 0.25rem !important;
  }
}

.content-text {
  margin-bottom: 0;

  @media screen and (max-width: $small) {
    font-size: 1rem !important;
  }
}

.content-text-border {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
}

.table-extra-btn {
  width: 18px;
  height: 18px;
  transform: rotate(0);
  transition: transform 0.3s ease-in-out;
  transform-origin: 8px 10px;
}

.table-extra-btn.rotate {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.table-extra {
  display: none;

  table {
    margin: 0 !important;

    th,
    td {
      border: none !important;
    }

    th {
      padding: 0 0.75rem 0.25rem 0rem !important;
    }

    td {
      padding: 0.25rem 0.75rem 0rem 0rem !important;
    }
  }
}

.table-extra.open {
  display: table-row;
}

.table-tr-done {
  td {
    color: $grey;
  }
}

.table-tr-debt {
  td {
    color: $red !important;
  }
}

.table-td-date {
  color: $header !important;
  span {
    display: block;
    font-size: 1.5rem;
    margin-top: 1rem;
  }
}

.table-td-teacher {
  display: block;
  font-style: italic;
  margin-top: 0.4rem;
}

.hint {
  color: $grey;
}

.descr-info {
  font-size: 0.75rem !important;

  @media screen and (max-width: $small) {
    font-size: 0.625rem;
  }
}

.form-title {
  border-bottom: 3px solid $grey;
  h4 {
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    font-weight: 300;

    @media screen and (max-width: $small) {
      font-size: 1rem;
    }
  }
}

.form-content {
  padding: 2.25rem 0;
  transition: border-color 0.3s ease-in-out;

  @media screen and (max-width: $small) {
    padding: 1.25rem 0;
  }
}

.form-content-item:not(:last-child) {
  margin-bottom: 2.5rem;

  @media screen and (max-width: $small) {
    margin-bottom: 1.25rem;
  }
}

.block {
  background: $gray-900;
}

.form-required {
  label:first-child,
  .form-check:only-of-type.required label {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      background-repeat: no-repeat;
      width: 8px;
      height: 8px;
      margin-right: 10px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.73535 7.75C3.73535 7.88807 3.84728 8 3.98535 8C4.12342 8 4.23535 7.88807 4.23535 7.75V4.47109L6.65684 6.89258C6.75447 6.99021 6.91276 6.99021 7.01039 6.89258C7.10802 6.79495 7.10802 6.63665 7.01039 6.53902L4.61355 4.14219H7.75C7.88807 4.14219 8 4.03026 8 3.89219C8 3.75412 7.88807 3.64219 7.75 3.64219H4.60332L6.65684 1.58867C6.75447 1.49104 6.75447 1.33275 6.65684 1.23512C6.5592 1.13749 6.40091 1.13749 6.30328 1.23512L4.23535 3.30305V0.25C4.23535 0.111929 4.12342 0 3.98535 0C3.84728 0 3.73535 0.111929 3.73535 0.25V3.26399L1.70709 1.23572C1.60946 1.13809 1.45117 1.13809 1.35353 1.23572C1.2559 1.33335 1.2559 1.49165 1.35353 1.58928L3.40645 3.64219H0.25C0.111929 3.64219 0 3.75412 0 3.89219C0 4.03026 0.111929 4.14219 0.25 4.14219H3.39621L0.999981 6.53842C0.90235 6.63605 0.90235 6.79434 0.999981 6.89197C1.09761 6.9896 1.2559 6.9896 1.35353 6.89197L3.73535 4.51016V7.75Z' fill='%23FB2A2A'/%3E%3C/svg%3E");
    }
  }
}

.asterisk {
  display: block;
  width: 8px;
  height: 8px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.73535 7.75C3.73535 7.88807 3.84728 8 3.98535 8C4.12342 8 4.23535 7.88807 4.23535 7.75V4.47109L6.65684 6.89258C6.75447 6.99021 6.91276 6.99021 7.01039 6.89258C7.10802 6.79495 7.10802 6.63665 7.01039 6.53902L4.61355 4.14219H7.75C7.88807 4.14219 8 4.03026 8 3.89219C8 3.75412 7.88807 3.64219 7.75 3.64219H4.60332L6.65684 1.58867C6.75447 1.49104 6.75447 1.33275 6.65684 1.23512C6.5592 1.13749 6.40091 1.13749 6.30328 1.23512L4.23535 3.30305V0.25C4.23535 0.111929 4.12342 0 3.98535 0C3.84728 0 3.73535 0.111929 3.73535 0.25V3.26399L1.70709 1.23572C1.60946 1.13809 1.45117 1.13809 1.35353 1.23572C1.2559 1.33335 1.2559 1.49165 1.35353 1.58928L3.40645 3.64219H0.25C0.111929 3.64219 0 3.75412 0 3.89219C0 4.03026 0.111929 4.14219 0.25 4.14219H3.39621L0.999981 6.53842C0.90235 6.63605 0.90235 6.79434 0.999981 6.89197C1.09761 6.9896 1.2559 6.9896 1.35353 6.89197L3.73535 4.51016V7.75Z' fill='%23FB2A2A'/%3E%3C/svg%3E");
}

.table-schedule {
  border-bottom: 3px solid $grey;
  margin-bottom: 0;
}

.table-anchors {
  display: flex;
  justify-content: space-between;
  a {
    display: inline-block;
    text-align: center;
    padding: 0.5rem;
    border: 1px solid $grey;
    border-radius: 0.25rem;
    color: $black;
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border-color: $link;
      transition: border-color 0.3s ease-in-out;
    }
  }
}

.table-tr-link {
  cursor: pointer;

  td {
    transition: color 0.3s ease-in-out;
  }

  svg {
    transition: transform 0.3s ease-in-out;
  }

  &:hover,
  &:active,
  &:focus {
    td {
      color: $link !important;
      transition: color 0.3s ease-in-out;
    }
    svg {
      transform: translateY(2px);
      transition: transform 0.3s ease-in-out;
    }
  }
}

.toggle-button {
  padding: 0.75rem 2.5rem;
  background: $white !important;
  border: 1px solid $dark-grey !important;
  color: $black !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: $link !important;
    color: $black !important;
    background: $white !important;
    transition: all 0.3s ease-in-out;
  }

  &:active {
    border-color: $header !important;
  }

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  @media screen and (max-width: $small) {
    padding: 0.5rem 1.5rem;
  }
}

.toggle-button-active {
  border-color: $header !important;
}

.round-button {
  min-width: 20px;
  min-height: 20px;
  font-size: 0.875rem;
  color: $grey;
  background: $light-grey;
  border-radius: 50%;
}

.selectList {
  display: flex;
  margin-bottom: 2rem;

  li {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    button {
      padding: 0.375rem 0.75rem;
      border-radius: 0.25rem;
      border: 1px solid var(----500, $grey);
      background: $white;
    }
    button.selected {
      border-color: $header;
    }
  }
}

.custom-link {
  cursor: pointer;
  color: $link;
  transition: color 0.3s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    color: $header !important;
    transition: color 0.3s ease-in-out;
  }
}
