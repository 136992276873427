ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  font-size: 1rem !important;
  color: $black;
}

a {
  color: $link;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    color: $link-hover;
    transition: color 0.3s ease-in-out;
  }
}

a.active {
  color: $link-hover;
  text-decoration: underline;
}

button {
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
}
