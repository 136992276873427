.button {
  stroke: #000;
  &:disabled {
    stroke: #999;
  }
  &:active {
    stroke: #f2f1f1;
  }
  &:hover {
    stroke: #f2f1f1;
  }
}
