@import "../../scss/variables";

.header {
  margin-bottom: 2.5rem;

  @media screen and (max-width: $small) {
    margin-bottom: 1.75rem;
  }
}

.headerLink {
  svg {
    height: 60px;
    margin-right: 0.25rem;

    @media screen and (max-width: $small) {
      width: 25px;
      height: 25px;
      margin-right: 0.25rem;
    }
  }
  span {
    font-size: 0.55rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $header;

    @media screen and (max-width: $small) {
      font-size: 0.75rem;
    }
  }
}

.noticeBtn {
  svg {
    width: 20px;
    height: 24px;
    path {
      transition: fill 0.3s ease-in-out;
    }
    @media screen and (max-width: $small) {
      width: 18px;
      height: 21px;
    }
  }
  &:hover {
    svg {
      path {
        fill: $link-hover;
        transition: fill 0.3s ease-in-out;
      }
    }
  }
}

.burgerBtn {
  svg {
    width: 36px;
    height: 25px;

    @media screen and (max-width: $small) {
      width: 28px;
      height: 25px;
    }
  }
}
