.body {
  margin-top: 24px;
  font-size: 0.875rem;
  color: #999;
  transition: color 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #4b4b4b !important;
    transition: color 0.3s ease-in-out;
  }
}

.button {
  width: 110px;
  background-color: white;
}
