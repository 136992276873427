//colors
$background: #f8f8f8;
$black: #000;
$white: #fff;
$header: #1c00ec;
$link: rgb(0, 123, 255);
$link-hover: #0056b3;
$grey: #999;
$dark-grey: #4b4b4b;
$light-grey: #f0f0f0;
$red: #f3384a;

//breakpoints
$large: 1024px;
$medium: 768px;
$small: 412px;
