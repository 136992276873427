@import "./variables";
/* make the customizations */
$grid-breakpoints: (
  xs: 0,
  sm: 413px,
  md: 769px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$spacer: 1rem !default; //16px
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  //4px
  2: $spacer * 0.5,
  //8px
  3: $spacer,
  //16px
  4: $spacer * 1.75,
  //28px
  5: $spacer * 2.25,
  //36px
  6: $spacer * 2.5,
  //40px
  7: $spacer * 3,
  //48px
) !default;

//buttons

.btn {
  @media screen and (max-width: $small) {
    font-size: 0.875rem !important;
  }
}

.btn-lg {
  --bs-btn-padding-y: 1rem !important;
  --bs-btn-padding-x: 1.75rem !important;
  --bs-btn-font-size: 1rem !important;
  --bs-btn-border-radius: 0.25rem !important;
  --bs-btn-font-weight: 700 !important;
  border: none !important;
  line-height: normal !important;

  @media screen and (max-width: $small) {
    --bs-btn-padding-y: 0.5rem !important;
    --bs-btn-padding-x: 1.25rem !important;
    --bs-btn-font-size: 0.875rem !important;
  }
}

.btn-sm {
  --bs-btn-padding-y: 0.375rem !important;
  --bs-btn-padding-x: 3rem !important;
  --bs-btn-font-size: 0.875rem !important;
  --bs-btn-border-radius: 0.25rem !important;
  --bs-btn-font-weight: 700 !important;
  border: none !important;
  line-height: normal !important;

  @media screen and (max-width: $small) {
    --bs-btn-padding-y: 0.2rem !important;
    --bs-btn-padding-x: 1.6rem !important;
  }
}

.btn-primary {
  --bs-btn-bg: #007bff !important;
  --bs-btn-hover-bg: #0056b3 !important;
  --bs-btn-active-bg: #0056b3 !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-color: #fff;
  --bs-btn-active-color: #fff !important;
  --bs-btn-disabled-color: #999 !important;
  --bs-btn-focus-shadow-rgb: #fff !important;
  --bs-btn-active-shadow: #fff !important;

  box-shadow: 0px 4px 4px 0px rgba(28, 0, 236, 0.2) !important;

  &:disabled {
    border: 1px solid #999 !important;
    box-shadow: 0px 4px 4px 0px rgba(217, 217, 217, 0.3) !important;
  }
}

.btn-secondary {
  --bs-btn-bg: #f2f1f1 !important;
  --bs-btn-hover-bg: #007bff !important;
  --bs-btn-active-bg: #007bff !important;
  --bs-btn-disabled-bg: #f2f1f1 !important;
  --bs-btn-color: #000 !important;
  --bs-btn-hover-color: #f2f1f1 !important;
  --bs-btn-active-color: #f2f1f1 !important;
  --bs-btn-disabled-color: #999 !important;
  border: 1px solid #007bff !important;

  &:disabled {
    border: none !important;
  }
}

.table {
  --bs-table-border-color: #999 !important;
  thead {
    tr {
      vertical-align: top;
    }
    th {
      padding: 0 1.25rem 0.75rem 0;
      color: $grey;
      font-weight: 600;
      font-size: 0.875rem;
      text-transform: uppercase;
      border-bottom: 2px solid $grey;

      @media screen and (max-width: $medium) {
        padding: 0rem 0.75rem 0.75rem 0rem !important;
      }
    }
  }
  td {
    padding: 1.5rem 1.25rem 0.75rem 0rem !important;

    @media screen and (max-width: $medium) {
      padding: 0.75rem 0.75rem 0.5rem 0rem !important;
    }
  }
  tr {
    vertical-align: middle;
  }
}

.form-label {
  font-weight: 700;
  margin-bottom: 1rem;

  @media screen and (max-width: $small) {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
}

.form-control {
  &:focus {
    border-color: $header !important;
    transition: border-color 0.3s ease-in-out !important;
    box-shadow: none !important;
  }

  @media screen and (max-width: $small) {
    font-size: 0.875rem !important;
  }
}

textarea.form-control {
  min-height: 300px !important;
  resize: none;

  @media screen and (max-width: $small) {
    min-height: 150px !important;
  }
}

.form-check-input[type="radio"] {
  border-color: $header !important;
  border-width: 2px;

  &:checked {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4' fill='%231C00EC'/%3E%3C/svg%3E") !important;
    background-color: $white !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.form-check-label {
  @media screen and (max-width: $small) {
    font-size: 0.875rem !important;
  }
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12' fill='none'%3E%3Cpath d='M2 2L8.29032 9L15 2' stroke='%23007BFF' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") !important;
  background-position: right 2.25rem center;
  background-size: 17px 12px;

  &:focus {
    border-color: $header !important;
    box-shadow: none !important;
  }

  @media screen and (max-width: $small) {
    font-size: 0.75rem !important;
  }
}

.form-select-custom {
  @media screen and (max-width: $small) {
    font-size: 0.875rem !important;
  }
}

.form-select-custom > div:first-of-type {
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;

  span {
    display: none;
  }

  svg {
    display: none;
  }

  &::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12' fill='none'%3E%3Cpath d='M2 2L8.29032 9L15 2' stroke='%23007BFF' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E");
    margin-right: 0.75rem;
    width: 17px;
    height: 12px;
  }
}

.form-check-input[type="checkbox"] {
  width: 1.25rem !important;
  height: 1.25rem !important;
  margin-top: 0;

  &:focus {
    border-color: $header !important;
    box-shadow: none !important;
  }

  &:checked {
    background-color: $white !important;
    border-color: $header !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M5 8.68421L9.5 15L15 5' stroke='%231C00EC' stroke-width='2' stroke-linecap='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_550_12298' x1='3.95604' y1='4.28571' x2='15.9341' y2='16.1538' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%231C00EC'/%3E%3Cstop offset='1' stop-color='%23007BFF'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E") !important;
  }
}

@import "~bootstrap/scss/bootstrap";
